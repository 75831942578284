/*==============================
=          GLOBAL  JS          =
==============================*/
$(function() {
	/* Toggle location */
	$("#maplink").click( function(){
		$("#location").slideToggle();
	});
	$("#maplinkSp").click( function(){
		$("#locationSp").slideToggle();
	});
	/* Toggle Reservations
		$("#reservations-widget").toggle();
		$("#reservations-link").click( function(){
			$("#reservations-widget").slideToggle();
		});*/
	/* Responsive nav config */
	var nav = responsiveNav(".nav-collapse", { // Selector
		animate: true, // Boolean: Use CSS3 transitions, true or false
		transition: 284, // Integer: Speed of the transition, in milliseconds
		label: "", // String: Label for the navigation toggle
		insert: "before", // String: Insert the toggle before or after the navigation
		customToggle: "", // Selector: Specify the ID of a custom toggle
		closeOnNavClick: false, // Boolean: Close the navigation when one of the links are clicked
		openPos: "relative", // String: Position of the opened nav, relative or static
		navClass: "nav-collapse", // String: Default CSS class. If changed, you need to edit the CSS too!
		navActiveClass: "js-nav-active", // String: Class that is added to  element when nav is active
		jsClass: "js", // String: 'JS enabled' class which is added to  element
		init: function(){}, // Function: Init callback
		open: function(){}, // Function: Open callback
		close: function(){} // Function: Close callback
	});

	/* Menu tabs */
	$(".menu__subnavItem").click( function(){
		//reset the active subnav menu item
		$(".menu__subnavItem").removeClass("active");
		$(this).addClass("active");

		//activate the selected menu section
		$(".menu__section").removeClass("active");
		var sectionId = $(this).data("section");
		$('#' + sectionId).addClass("active");
	});

	/* Menu location switching */
	$("#sunprairie").click( function(){
		$(this).addClass("active");
		$("#madison").removeClass("active");
		$(".menu__item--sunprairie").show();
	});
	$("#madison").click( function(){
		$(".menu__item--sunprairie").hide();
		$(this).addClass("active");
		$("#sunprairie").removeClass("active");
	});
});
